import Board from '../../components/connect4/Board';
import "@fontsource/roboto"
import React from "react"
import "../index.css";
import Header from '../../components/header';

const headerDetails = {
  title: 'Connect 4 with MCTS AI',
  img: '/connect4.png',
  description: 'Play against a computer in connect 4. It uses a Monte Carlo Search tree to determine it\'s next best move.',
}

export default function Home() {
  return (
  <Header details={headerDetails}>
    <div style={{textAlign: 'center'}}>
      <Board></Board>
    </div>
  </Header>
  );
}
